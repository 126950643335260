<template>
  <v-container align="center" class="text-center py-12 my-10">
    <h2>{{ $t('failedPayment') }}</h2>
  </v-container>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({})
</script>
