<template>
  <v-container align="center" class="text-center py-12 my-10">
    <h2>{{ $t('successfulPayment') }}</h2>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters(['authStatus', 'authStatusEnum'])
  },
  async mounted() {
    await this.getUserStatus()
    // Stripe webhook might take some time to process
    while (this.authStatus !== this.authStatusEnum.ACTIVE) {
      window.setTimeout(async () => {
        await this.getUserStatus()
      }, 1000)
    }
  },
  methods: {
    ...mapActions(['getUserStatus'])
  }
})
</script>
