<template>
  <v-container grid-list-s class="pt-4">
    <v-row>
      <v-col col="3" md="3" class="d-none d-sm-flex"><SideNav /> </v-col>
      <v-divider vertical />
      <v-col col="8" md="8" sm="8">
        <v-row>
          <v-container>
            <h2 class="text-h4 font-weight-bold primary--text text-capitalize ">
              {{ $t('subscriptions') }}
            </h2>
            <v-row>
              <v-col>
                <h4
                  v-if="userProfile.user.status === authStatusEnum.ACTIVE"
                  class="text-subtitle font-weight-bold secondary--text my-4"
                >
                  {{ $t('subscriptionValidity') }}
                  {{
                    userProfile.user.subscription_ends_at
                      | toLocalTZ
                      | moment('D, MMM YYYY, h:mm a')
                  }}
                </h4>
                <h4
                  v-else
                  class="text-subtitle font-weight-bold secondary--text my-4"
                >
                  {{ $t('subscriptionExpired') }}
                </h4>
              </v-col>
              <v-col>
                <div class="justify-end d-flex">
                  <v-btn depressed color="primary" @click="renewSubscription">
                    {{ $t('renewSubscription') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-4" />

            <v-row
              ><v-col>
                <h4 class="text-subtitle font-weight-bold secondary--text my-4">
                  {{ $t('paymentHistory') }}
                </h4></v-col
              ></v-row
            >
            <v-row>
              <v-list subheader two-line>
                <v-list-item
                  v-for="(subscription, i) in subscriptions"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('paymentHistoryText_1') }}
                      {{ subscription.duration }}
                      {{ $t('paymentHistoryText_2') }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-caption"
                      >{{ $t('paymentId') }}
                      {{ subscription.payment_id }}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-action>
                    {{
                      subscription.payment_time
                        | toLocalTZ
                        | moment('from', 'now')
                    }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-row>
            <v-row v-if="loadMoreSubscriptions" justify="center" class="my-10">
              <v-btn depressed color="primary" @click="getMoreSubscription">
                {{ $t('loadMore') }}
              </v-btn>
            </v-row>
            <v-divider></v-divider>
            <SubscriptionModal
              default-selected-option="10"
              :dialog-is-open="isOpen"
              :modal-title="$t('renewSubscription')"
              :options="[
                { label: $t('tenYears'), value: '10', price: '750' },
                { label: $t('fiveYears'), value: '5', price: '400' },
                { label: $t('oneYear'), value: '1', price: '100' }
              ]"
              @handleClose="isOpen = false"
            />
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import SideNav from '@/components/SideNav.vue'
import SubscriptionModal from '@/components/SubscriptionModal.vue'

export default Vue.extend({
  components: {
    SideNav,
    SubscriptionModal
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapGetters([
      'subscriptions',
      'loadMoreSubscriptions',
      'userProfile',
      'authStatusEnum'
    ])
  },
  beforeMount() {
    this.getUser()
    this.getSubscriptionHistory()
  },
  methods: {
    ...mapActions(['getSubscriptionHistory', 'getMoreSubscription', 'getUser']),
    renewSubscription() {
      this.isOpen = true
    }
  }
})
</script>
